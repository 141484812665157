<template>
  <div class="rights">
    <ul>
      <li class="rightItem" v-for="(item, index) in rightArray" :key="item.key">
        <img
          class="topImg"
          :src="require(`@/assets/img/huaxia/top${index + 1}.png`)"
        />
        <div class="rightItemContent" :class="`top${index + 1}`">
          <div
            class="goodItem"
            v-for="(good, index) in item.value"
            :key="index"
            :class="good.active ? 'active' : ''"
            @click="
              item.value = item.value.map(item =>
                item.goodKey === good.goodKey
                  ? { ...item, active: true }
                  : { ...item, active: false }
              )
            "
          >
            <h5 v-html="good.goodValue" />
            <div class="price"><span>￥</span>{{ item.key || 0 }}</div>
            <img
              v-if="good.active"
              class="goodActive"
              src="~@/assets/img/huaxia/active.png"
            />
            <img
              v-if="!userRight.map(v => v.rightLevel).includes(+item.key)"
              class="ineligible"
              src="~@/assets/img/huaxia/ineligible.png"
            />
          </div>
          <div class="tips">
            持卡人上月消费达标后，次月可获得1次0元购优惠权益，以上权益N选一
          </div>
          <!-- rightStatus状态: 0：待领取 1：已领取 2：领取失败 3：领取中 4已过期 -->
          <!-- userRight存在item.key && rightStatus === 0 -->
          <van-button
            class="exchangeBtn"
            :disabled="canExchange(item)"
            @click="doExchange(item)"
          >
            领取权益
          </van-button>
        </div>
      </li>
    </ul>
    <van-overlay :show="modalShow" @click="modalShow = false" lock-scroll>
      <div class="modalBox" @click.stop>
        <div class="modalText">
          <h3>温馨提示</h3>
          <div>
            券码信息稍后以短信的形式发送至您{{
              $route.query.phone
            }}手机号，是否确认领取
          </div>
        </div>
        <van-button
          class="modalBtn"
          loading-text="加载中..."
          :loading="loading"
          @click="submitMeds(exchangeItem)"
        >
          确定
        </van-button>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { getRightInfo, huaxiaExchange } from '@/api/huaxia'
export default {
  data() {
    return {
      rightArray: {},
      userRight: [],
      modalShow: false,
      Modaltext: undefined,
      exchangeItem: [],
      loading: false
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      getRightInfo().then(({ data }) => {
        const rightArray = Object.entries(data.rightMap).map(
          ([key, value]) => ({
            key,
            value: Object.entries(value).map(([goodKey, goodValue], index) => ({
              goodKey,
              goodValue,
              active: index === 0
            }))
          })
        )
        console.log(rightArray)
        this.rightArray = rightArray
        this.userRight = data.userRight
      })
    },
    // 是否能点击
    canExchange(item) {
      const hasRight = this.userRight.some(
        v => +v.rightLevel === +item.key && v.rightStatus === 0
      )
      return !hasRight
    },
    doExchange(item) {
      this.modalShow = true
      this.exchangeItem = item
    },
    async submitMeds(item) {
      try {
        this.loading = true
        this.modalShow = false
        const { code, message } = await huaxiaExchange({
          rightLevel: item.key,
          goodsNo: item?.value?.find(v => v.active)?.goodKey,
          token: sessionStorage.getItem('huaxiaToken')
        })

        this.loading = false
        if (code === 0) {
          this.$toast.success(message || '领取成功')
          this.loadData()
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.rights {
  padding: 40px 15px 50px;
  box-sizing: border-box;
  background: #f3f3f5 url(~@/assets/img/huaxia/bg2.png) center top no-repeat;
  background-size: 100% auto;
  .rightItem {
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }
    .topImg {
      width: 85%;
      display: block;
      margin: auto;
      z-index: 1;
    }
    .rightItemContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: -14px;
      border: 1px solid #ffffff;
      padding: 50px 10px 30px;
      position: relative;
      z-index: 2;
      border-radius: 15px;
      &.top1 {
        background: linear-gradient(0deg, #fff2d5, #ffffff);
        box-shadow: 0px 4px 11px 0px rgba(178, 178, 178, 0.18);
        .active {
          background: #fff4c8 !important;
          border: 2px solid #fbcc91 !important;
        }
      }
      &.top2 {
        background: linear-gradient(0deg, #ffdfcf, #ffffff);
        box-shadow: 0px 8px 21px 0px rgba(178, 178, 178, 0.18);
        .active {
          background: #fff0e3 !important;
          border: 2px solid #fbb491 !important;
        }
      }
      &.top3 {
        background: linear-gradient(0deg, #e7eaff, #ffffff);
        box-shadow: 0px 8px 21px 0px rgba(178, 178, 178, 0.18);
        .active {
          background: #eff4ff !important;
          border: 2px solid #b6c1eb !important;
        }
      }
      .goodItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 110px;
        margin: 5px 2px;
        text-align: center;
        background: #ffffff;
        box-shadow: 0px 1px 0px 0px rgba(178, 178, 178, 0.18);
        border-radius: 10px;
        border: 1px solid #fef2d8;
        position: relative;
        .goodActive {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 28px;
        }
        .ineligible {
          position: absolute;
          top: 42px;
          width: 80px;
        }
        h5 {
          width: 80%;
        }
        .price {
          font-size: 24px;
          color: #fe502d;
          padding-top: 10px;
          span {
            font-size: 12px;
          }
        }
      }
    }
    .exchangeBtn {
      display: block;
      width: 90%;
      background: #030001;
      color: #fff1df;
      border-radius: 49px;
      border: none;
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translateX(-50%);
      right: 0;
      &.van-button--disabled {
        opacity: 1;
        background: #c4c4c4;
        color: #7b7b7b;
      }
    }
    .tips {
      font-size: 12px;
      color: #808080;
      padding: 22px 0 15px;
    }
  }
  .van-overlay {
    z-index: 999;
  }
  .modalBox {
    width: 300px;
    height: 312px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(~@/assets/img/huaxia/modalBg.png);
    background-size: 300px 312px;
    .modalText {
      color: #030001;
      font-size: 14px;
      text-align: center;
      h3 {
        padding: 60px 0 20px;
        font-size: 24px;
      }
      div {
        padding: 0 50px 0 40px;
        line-height: 22px;
      }
    }
    .modalBtn {
      position: absolute;
      bottom: 36px;
      left: 50%;
      transform: translateX(-50%);
      width: 167px;
      height: 43px;
      line-height: 43px;
      background: #030001;
      border-radius: 21px;
      text-align: center;
      font-size: 18px;
      color: #fff1df;
    }
  }
}
</style>
